import {
  Collapse,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { ListItemButton } from '@mui/material';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  listContainerButtonStyle: {
    '& .MuiListItemText-root > .MuiTypography-body1': {
      fontSize: '1.2rem !important'
    },
    borderRight: '2px solid orange !important'
  },
  listItemButtonStyle: {
    '& .MuiListItemText-root > .MuiTypography-body1': {
      fontSize: '1.2rem !important'
    },
    paddingLeft: '2.2rem !important',
    borderRight: '2px solid orange !important',
    '&.Mui-selected': {
      backgroundColor: 'unset !important',
      borderTop: '2px solid orange',
      borderBottom: '2px solid orange',
      borderRight: 'none !important'
    }
  },
  listItemCheckedFacet: {
    '& .MuiListItemText-root > .MuiTypography-body1': {
      fontWeight: '800 !important',
      fontSize: '1.2rem !important'
    },
    paddingLeft: '2.2rem !important',
    borderRight: '2px solid orange !important',
    '&.Mui-selected': {
      backgroundColor: 'unset !important',
      borderTop: '2px solid orange',
      borderBottom: '2px solid orange',
      borderRight: 'none !important'
    }
  }
}));

const hasFacetCheckedValues = (checked, facet) => {
  if (facet == undefined) {
    return false;
  }
  if (checked.length != 0) {
    for (let i = 0; i < checked.length; i++) {
      if (checked[i]?.searchIds[0] === facet?.searchIds[0]) {
        return true;
      }
    }
    return false;
  } else {
    return false;
  }
};

export default function FacetContainer({
  containerLabel,
  containerFacets,
  handleListItemClick,
  allSearchIds,
  facetElement,
  checked
}) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem
        button
        onClick={handleClick}
        className={classes.listContainerButtonStyle}
      >
        <ListItemText primary={containerLabel} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {containerFacets.map((facet) => (
            <ListItemButton
              key={facet.facetBoxId}
              className={
                hasFacetCheckedValues(checked, facet)
                  ? classes.listItemCheckedFacet
                  : classes.listItemButtonStyle
              }
              selected={facet.facetBoxId === facetElement.facetBoxId}
              onClick={() => handleListItemClick(facet)}
            >
              <ListItemText
                primary={
                  <Typography
                    fontSize={12}
                    className={
                      allSearchIds.indexOf(facet?.searchIds[0]) != -1
                        ? classes.facet
                        : ''
                    }
                  >
                    {facet.label}
                  </Typography>
                }
              />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
}
