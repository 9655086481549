import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText
} from '@material-ui/core';

import { useTitleStyles } from './featurecomponents/FeatureComponent';
import { useDataManagement } from './DataManagementContext';

export const ArticleDataZoneEditor = ({
  instanceEditor,
  value,
  isError,
  helper,
  changeGeneralField,
  saveGeneralField
}) => {
  const { t } = useTranslation();
  const classesTitle = useTitleStyles();

  const { articleDataZones } = useDataManagement();

  let title;

  if (!articleDataZones) return t('managUsers.register.defaultDataZone');

  let missingZoneError = false;
  if (value && !articleDataZones.some((zone) => zone.id === value)) {
    missingZoneError = true;
    value = 'missingZoneError';
    helper = t('instanceEditor.articleDataZone.missingZoneError');
  }

  return (
    <FormControl
      variant="outlined"
      error={isError || missingZoneError}
      size="small"
    >
      <InputLabel
        shrink={false}
        className={classesTitle.titleSelect}
        id={'input-label-datazones' + instanceEditor.id}
      >
        {title}
      </InputLabel>
      <Select
        size="small"
        value={value}
        onChange={changeGeneralField}
        onBlur={saveGeneralField}
        displayEmpty={true}
        labelId={'input-label-datazones' + instanceEditor.id}
        className={classesTitle.booleanInputStyle}
      >
        {missingZoneError && (
          <MenuItem value="missingZoneError">
            <span>&nbsp;</span>
          </MenuItem>
        )}
        <MenuItem value={''}>
          <em>{t('dashboard.articleDataZones.dataZoneDefault')}</em>
        </MenuItem>
        {articleDataZones.map((dataZone) => (
          <MenuItem key={dataZone.id} value={dataZone.id}>
            {dataZone.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText component="div">{helper}</FormHelperText>
    </FormControl>
  );
};
