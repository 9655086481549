import React, { useContext, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import { useDataManagement } from './DataManagementContext';
import { useCreateInstance } from './create-instances/CreateInstanceContext';
import { TableDataContext } from '../tabeditor/tools/Table';
import { useTitleStyles } from './featurecomponents/FeatureComponent';

import ajaxPost from 'src/services/ajaxPost';
import ajaxCall from 'src/services/ajaxCall';
import { ArticleDataZoneEditor } from './ArticleDataZoneEditor';

const useStyles = makeStyles(() => ({
  abcEditorInTable: {
    maxWidth: '25rem',
    width: '16rem',
    '& > .MuiInputBase-root': {
      height: '44px'
    }
  },
  input: {
    '&:invalid': {
      border: 'red solid 2px'
    }
  }
}));

export const GeneralFieldEditor = React.memo(
  ({ generalFieldType, instanceEditor }) => {
    const { t } = useTranslation();
    const { getGeneralFieldState, setGeneralFieldState } = useDataManagement();
    const {
      isCreatingInstance,
      isDuplicateInstance,
      setCreationParameter,
      setCreationComponentRef
    } = useCreateInstance();
    const [isInTableContext] = useContext(TableDataContext);

    const classes = useStyles();
    const classesTitle = useTitleStyles();

    const instanceId = instanceEditor.instanceId;
    const generalFieldValue = getGeneralFieldState(
      instanceId,
      generalFieldType
    );

    const [error, setError] = useState('');
    const [inputState, setInputState] = useState(generalFieldValue);
    const [blurState, setBlurState] = useState(generalFieldValue);

    const componentRef = useRef(null);
    const creatingInstance = isCreatingInstance();
    const duplicateInstance = isDuplicateInstance();

    useEffect(() => {
      if (creatingInstance && !duplicateInstance) {
        setCreationComponentRef(
          generalFieldType,
          componentRef,
          isMandatoryError
        );
      }
    }, [isMandatoryError]);

    let title;
    let url = 'dataManagement/';
    let resetUrl = url;
    let mandatory = false;

    let requestMethod;
    const params = {};

    switch (generalFieldType) {
      case 'articleNumber':
        title = t('data.articleNumber');
        url += 'editArticleNumber';
        params.articleId = instanceId;
        params.articleNumber = inputState;
        mandatory = true;
        break;
      case 'articleDataZone':
        title = t('instanceEditor.articleDataZone.name');
        url += 'articleDataZone';
        resetUrl += 'articleDataZone';
        requestMethod = 'put';
        params.id = instanceId;
        params.instanceTypeTarget = instanceEditor.instanceType;
        params.articleDataZoneId = inputState;
        mandatory = false;
        break;
      default:
        return null;
    }

    const isMandatoryError = mandatory && !inputState;
    let helper = '';
    if (isMandatoryError) {
      helper = t('common.errorMandatory');
    }

    function changeGeneralField(event) {
      const newVal = event.target.value;
      setInputState(newVal);
    }

    function saveGeneralField() {
      let curVal = null;
      if (inputState) {
        curVal = inputState;
      }

      if ([blurState, ''].includes(curVal)) return;
      setBlurState(curVal);

      if (creatingInstance) {
        saveCreateValue(curVal);
        return;
      }

      if (isMandatoryError) return;

      setGeneralFieldState(instanceId, generalFieldType, curVal);
      if (curVal === null) {
        resetGeneralFieldValue();
      } else {
        saveGeneralFieldValue();
      }
    }

    function saveGeneralFieldValue() {
      ajaxCall({
        httpMethod: requestMethod || 'post',
        url: url,
        params: params
      }).then((ajaxData) => {
        let response = ajaxData.response;

        if (!ajaxData.error && response) {
          TOGO.Util.notifyResponse(response.message, !response.success);
        } else {
          setError(ajaxData.error);
        }
      });
    }

    function resetGeneralFieldValue() {
      if (requestMethod) {
        ajaxCall({
          httpMethod: 'delete',
          url: url,
          params: params
        }).then((ajaxData) => {
          let response = ajaxData.response;

          if (!ajaxData.error && response) {
            TOGO.Util.notifyResponse(response.message, !response.success);
          } else {
            setError(ajaxData.error);
          }
        });
      } else {
        ajaxPost(resetUrl, null, [instanceId]).then((ajaxData) => {
          let response = ajaxData.response;
          TOGO.Util.notifyResponse(response.message, !response.success);
          if (!ajaxData.error) {
            setBlurState(null);
          }
        });
      }
    }

    function saveCreateValue(curVal) {
      setCreationParameter(generalFieldType, curVal);
    }

    function showError() {
      if (!error) return;
      TOGO.Util.notifyResponse(error, true);
    }

    if (generalFieldType === 'articleDataZone') {
      return (
        <div
          ref={componentRef}
          className="instanceEditorComponent general-field-editor"
        >
          <ArticleDataZoneEditor
            instanceEditor={instanceEditor}
            value={inputState != null ? inputState : ''}
            isError={Boolean(error) || isMandatoryError}
            helper={helper}
            changeGeneralField={changeGeneralField}
            saveGeneralField={saveGeneralField}
          />
        </div>
      );
    }

    return (
      <div
        ref={componentRef}
        className="instanceEditorComponent general-field-editor"
      >
        <TextField
          label={isInTableContext ? null : title}
          InputLabelProps={{
            shrink: false,
            disableAnimation: true,
            className: classesTitle.title
          }}
          InputProps={{
            classes: {
              input: classesTitle.input__
            }
          }}
          style={{ minWidth: '25%', width: '100%' }}
          className={
            isInTableContext
              ? classes.abcEditorInTable
              : classesTitle.togoInputStyle
          }
          variant={isInTableContext ? 'standard' : 'outlined'}
          value={inputState != null ? inputState : ''}
          error={Boolean(error) || isMandatoryError}
          FormHelperTextProps={{ component: 'div' }}
          helperText={helper}
          onChange={changeGeneralField}
          onBlur={saveGeneralField}
          onMouseEnter={showError}
        />
      </div>
    );
  }
);
GeneralFieldEditor.displayName = 'GeneralFieldEditor';
