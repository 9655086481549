import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useTitleStyles } from '../../../articledata/datamanagement/featurecomponents/FeatureComponent';


import {
	Grid,
	Box,
	Button,
	MenuItem,
	InputLabel
} from '@material-ui/core';

import { usePimStyles } from '../../../PimTogoTheme';
import Textfield from '../../../user/FormsUI/TextField';
import ajaxGet from 'src/services/ajaxGet';
import ajaxPost from 'src/services/ajaxPost';
import FocusError from './FocusError';
import { getUserCountryLang } from 'src/util/languageUtil';
import Select from '../../../user/FormsUI/Select';




export const TableDefinitionForm = ({
	handleCloseDialogTableDefinition,
	initFormTableDefinitionState,
	toCreateTableDefinition,
	// contentLangSelections,
	searchTableDefinitions
}) => {

	const { t } = useTranslation();

	const [language, setLanguage] = useState(t('language.code'));
  // const [tableDefinitionColumns, setTableDefinitionColumns] = useState('features');

	const userLang = getUserCountryLang();

	const classesTitle = useTitleStyles();

	const classesPim = usePimStyles();

	const formikRef = useRef();

	const FORM_VALIDATION_TABLE_DEFINITION_ = Yup.object().shape({
		id: '',
		language:'',
		tableDefinitionName: Yup.string().max(64, 'Muss nicht mehr als 64 Zeichen enthalten')
    .required(t('manageOrganization.organization.requiredFieldErrorMessage')),
		fieldSize:Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
		tableDefinitionColumns :Yup.string(),
// - Felder-DB (Artikeltext etc.)
		alignment: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
		order: Yup.number().required(t('manageOrganization.organization.requiredFieldErrorMessage')).positive().integer(),
		///	.required(t('manageOrganization.organization.requiredFieldErrorMessage'))d
	});
	
	const fieldSizesList = {width_50: '50%', width_25: '25%', width_15: '15%', width_rest:'Rest'};

	const alignmentList = { horizontal: t('dashboard.tableDef.horizontal'), vertical: t('dashboard.tableDef.vertical') };

	const columnsList = { FA:t('dashboard.tableDef.features'), RM: 'RM_s' };

	return (
    <Formik
      initialValues={{
        ...initFormTableDefinitionState
      }}
      innerRef={formikRef}
      enableReinitialize={true}
      validationSchema={FORM_VALIDATION_TABLE_DEFINITION_}
      onSubmit={(values) => {
        console.log(values);
        let params = {};
        params.id = values?.id;
        params.tableDefinitionName = values?.tableDefinitionName;
        params.tableDefinitionColumns = values?.tableDefinitionColumns;
        var fieldSize = values?.fieldSize;

        if (fieldSize == "width_50"){
             fieldSize = 50;
        } else if (fieldSize ==  "width_25"){
          fieldSize =25;

        } else if (fieldSize ==  "width_15"){
          fieldSize =15;

        }else if (fieldSize ==  "width_rest"){
          fieldSize =-1;
        }

        params.fieldSize= fieldSize;
        params.alignment = values?.alignment;
        params.order = values?.order;
        params.language = language;


        if (toCreateTableDefinition) {
          ajaxPost('tableDefinition/create', params).then((ajaxData) => {
            if (!ajaxData.error && ajaxData.response) {
              TOGO.Util.notifyResponse(ajaxData.response.message, false);
              handleCloseDialogTableDefinition();
              searchTableDefinitions();
            }
          });
        } else {
          params.language = formikRef.current.values.language;
          ajaxPost('tableDefinition/editDone', params).then(
            (ajaxData) => {
              if (!ajaxData.error && ajaxData.response) {
                handleCloseDialogTableDefinition();
                TOGO.Util.notifyResponse(ajaxData.response.message, false);
                searchTableDefinitions();
              }
            }
          );
        }
      }}
    >
      <Form>
        <FocusError />
        <Box className={classesPim.gridItem}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Textfield
                name="tableDefinitionName"
                label={t('dashboard.tableDef.name')}
                InputLabelProps={{
                  shrink: false,
                  disableAnimation: true,
                  className: classesTitle.title
                }}
                className={classesTitle.togoInputStyle}
                variant={'outlined'}
              ></Textfield>
            </Grid>
			<Grid item xs={12}>
              <Select
                name="tableDefinitionColumns"
                label={t('dashboard.tableDef.columns')}
                options={columnsList}
                InputLabelProps={{
                  shrink: false,
                  disableAnimation: true,
                  className: classesTitle.title
                }}
                className={classesTitle.togoInputStyle}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                name="fieldSize"
                label={t('dashboard.tableDef.width')}
                options={fieldSizesList}
                InputLabelProps={{
                  shrink: false,
                  disableAnimation: true,
                  className: classesTitle.title
                }}
                className={classesTitle.togoInputStyle}
              />
              </Grid>
            <Grid item xs={12}>
              <Select
                name="alignment"
                label={t('dashboard.tableDef.alignment')}
                options={alignmentList}
                InputLabelProps={{
                  shrink: false,
                  disableAnimation: true,
                  className: classesTitle.title
                }}
                className={classesTitle.togoInputStyle}
              />
            </Grid>
            <Grid item xs={12}>
              <Textfield
                name="order"
                label={t('dashboard.tableDef.order')}
                type="number"
                InputLabelProps={{
                  shrink: false,
                  disableAnimation: true,
                  className: classesTitle.title
                }}

                InputProps={{
                  inputProps: { 
                      max: 100, min: 1 
                  }
              }}
                className={classesTitle.togoInputStyle}
                variant={'outlined'}
              ></Textfield>
            </Grid>
          </Grid>
        </Box>
        <div
          style={{
            display: 'inline-flex',
            width: '100%',
            position: 'sticky',
            bottom: '0',
            zIndex: '9'
          }}
        >
          <Button
            type="submit"
            className={`${classesPim.addAction} ${classesPim.dialogBottom}`}
            style={{ width: '50%' }}
          >
            {toCreateTableDefinition
              ? t('common.create')
              : t('managUsers.register.saveUser')}
          </Button>

          <Button
            onClick={handleCloseDialogTableDefinition}
            className={`${classesPim.deleteAction} ${classesPim.dialogBottom}`}
            style={{ width: '50%', marginLeft: '1rem' }}
          >
            {t('managUsers.register.cancel')}
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

export default TableDefinitionForm;
