import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormLabel, FormHelperText, LinearProgress } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { TableDataContext } from '../../tabeditor/tools/Table';
import { Numeric } from './Numeric';

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: '0.8rem',
    left: '0.5rem',
    position: 'relative',
    fontSize: '1.5rem !important',
    fontFamily: "'Roboto', sans-serif !important",
    color: '#636363 !important'
  },
  rangeWrap: {
    display: 'flex',
    '& > .MuiTextField-root': {
      maxWidth: '11.2rem'
    }
  },
  rangeSeperator: {
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 1rem'
  },
  irrelevantLabel: {
    position: 'relative',
    left: '7rem'
  }
}));

export const getRangePayloadData = (featureType, value) => {
  let rangePayload = {
    rangeValue: {}
  };

  switch (featureType) {
    case 'FEATURE_TYPE_NUMERIC_RANGE':
      rangePayload.rangeValue.endpointsFeatureType = 'FEATURE_TYPE_NUMERIC';
      rangePayload.rangeValue.rangeLowerEndpoint = {
        numericValue: value.lowerValue
      };
      rangePayload.rangeValue.rangeUpperEndpoint = {
        numericValue: value.upperValue
      };
      break;
    default:
      break;
  }

  return rangePayload;
};

export const Range = ({
  featureType,
  featureInfo,
  values,
  setValueState,
  saveChangedValue,
  loading,
  error
}) => {
  const { t } = useTranslation();
  const [isInTableContext] = useContext(TableDataContext);

  const irrelevantValue = Boolean(
    featureInfo.featureComponent?.irrelevantFeatureValue
  );
  const [markIrrelevant, setMarkIrrelevant] = useState(irrelevantValue);

  var lowerValue;
  var upperValue;
  if (values) {
    lowerValue = values.lowerValue;
    upperValue = values.upperValue;
  } else {
    lowerValue = '';
    upperValue = '';
  }
  const classes = useStyles();
  const title = isInTableContext ? null : featureInfo.title;

  const mandatory = featureInfo.mandatory;
  let isMandatoryError =
    !markIrrelevant && mandatory && !lowerValue && !upperValue;
  let helper = '';
  const hasDefaultData = featureInfo.hasDefaultData;

  error = error || '';
  if (loading) {
    helper = <LinearProgress color="secondary" />;
  } else if (isMandatoryError && !hasDefaultData) {
    helper = t('common.errorMandatory');
  }

  function setLowerValueState(newLowerValue) {
    const newRangeValue = {};
    newRangeValue.lowerValue = newLowerValue;
    newRangeValue.upperValue = upperValue;

    if (!newLowerValue && !upperValue) {
      // setIsMandatoryError(true);
      // setHelper(t('common.errorMandatory'));
      return;
    }

    setValueState(newRangeValue);
  }

  function setUpperValueState(newUpperValue) {
    const newRangeValue = {};
    newRangeValue.lowerValue = lowerValue;
    newRangeValue.upperValue = newUpperValue;

    if (!newUpperValue && !lowerValue) {
      // setIsMandatoryError(true);
      // setHelper(t('common.errorMandatory'));
      return;
    }

    setValueState(newRangeValue);
  }

  function saveLowerRangeValue(newValue) {
    let changedValue = { lowerValue: newValue, upperValue: upperValue };
    saveRangeValue(changedValue);
  }

  function saveUpperRangeValue(newValue) {
    let changedValue = { lowerValue: lowerValue, upperValue: newValue };
    saveRangeValue(changedValue);
  }

  function saveRangeValue(newValue) {
    if (loading) return;

    if (!newValue.lowerValue) {
      if (newValue.upperValue) {
        return;
      }

      if (mandatory && !hasDefaultData) {
        setValueState(newValue);
        return;
      }

      saveChangedValue(null);
      setMarkIrrelevant(false);
      return;
    }
    if (!newValue.upperValue) {
      if (newValue.lowerValue) {
        return;
      }

      if (mandatory && !hasDefaultData) {
        setValueState(newValue);
        return;
      }

      saveChangedValue(null);
      setMarkIrrelevant(false);
      return;
    }

    let rangePayload = getRangePayloadData(featureType, newValue);
    newValue;

    //if (markIrrelevant && (!newValue || newValue == '-')) {
    if (newValue.lowerValue == '-' || newValue.upperValue == '-') {
      rangePayload.irrelevantFeatureValue = true;
      rangePayload.rangeValue.rangeLowerEndpoint = null;
      rangePayload.rangeValue.rangeUpperEndpoint = null;
      saveChangedValue('-', rangePayload);
      setMarkIrrelevant(true);
      return;
    }

    saveChangedValue(newValue, rangePayload);
    setMarkIrrelevant(false);
  }

  switch (featureType) {
    case 'FEATURE_TYPE_NUMERIC_RANGE':
      return (
        <div>
          {!isInTableContext && (
            <FormLabel className={classes.title}>{title}</FormLabel>
          )}
          <form className={classes.rangeWrap}>
            <Numeric
              className="numeric-range"
              featureInfo={
                upperValue ? { ...featureInfo, mandatory: true } : featureInfo
              }
              number={lowerValue}
              setValueState={setLowerValueState}
              saveChangedValue={saveLowerRangeValue}
              title={isInTableContext ? null : t('instanceEditor.range.from')}
              markIrrelevantParent={markIrrelevant}
              mandatoryErrorMsg={t('instanceEditor.range.incomplete')}
              error={error}
            ></Numeric>
            <span className={classes.rangeSeperator}>-</span>
            <Numeric
              className="numeric-range"
              featureInfo={
                lowerValue ? { ...featureInfo, mandatory: true } : featureInfo
              }
              number={upperValue}
              setValueState={setUpperValueState}
              saveChangedValue={saveUpperRangeValue}
              title={isInTableContext ? null : t('instanceEditor.range.from')}
              markIrrelevantParent={markIrrelevant}
              mandatoryErrorMsg={t('instanceEditor.range.incomplete')}
              error={error}
            ></Numeric>
          </form>
          <FormHelperText
            error={Boolean(error) || (isMandatoryError && !hasDefaultData)}
          >
            {helper}
          </FormHelperText>
          {markIrrelevant && (
            <p className={classes.irrelevantLabel}>
              {t('instanceEditor.irrelevant')}
            </p>
          )}
        </div>
      );
  }
};
