import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useTranslation } from 'react-i18next';

import { useField, useFormikContext } from 'formik';
import {
  FormControlLabel,
  FormHelperText,
  Icon,
  makeStyles,
  OutlinedInput
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%'
  },
  dataZonesWrap: {
    maxWidth: '32rem',
    marginBottom: '2rem'
  },
  menuPaper: {
    maxHeight: 100
  },
  indeterminateColor: {
    color: '#5cb85c'
  },
  defaultZoneCheckbox: {
    marginLeft: '0rem'
  },
  checkAllListItem: {
    borderBottom: '1px solid green'
  },
  infoIcon: {
    fontSize: '2rem',
    position: 'relative',
    top: '0.6rem',
    marginRight: '0.5rem',
    color: '#5cb85c'
  },
  warningIcon: {
    fontSize: '2rem',
    position: 'relative',
    top: '0.6rem',
    marginRight: '0.5rem'
  }
}));

export const useTitleStyles = makeStyles(() => ({
  titleSelect: {
    top: '-2rem',
    left: '-0.6rem',
    position: 'relative',
    fontSize: '1.5rem !important',
    fontFamily: "'Roboto', sans-serif !important",
    color: '#636363 !important'
  },
  dataZoneTitle: {
    top: '0rem',
    left: '0.7rem',
    position: 'relative',
    fontSize: '1.5rem !important',
    fontFamily: "'Roboto', sans-serif !important",
    color: '#636363 !important'
  }
}));

const USER_DATAZONES_KEY = 'userDataZones';
const USER_DATAZONES_DEFAULT_KEY = 'userDataZonesDefault';

function UserDataZonesSelect({ dataZones }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const { setFieldValue } = useFormikContext();
  const [fieldSelectedDataZones] = useField(USER_DATAZONES_KEY);
  const [fieldUserDataZonesDefault] = useField(USER_DATAZONES_DEFAULT_KEY);

  const classesTitle = useTitleStyles();

  const [userDataZonesDefault, setUserDataZonesDefault] = useState(
    fieldUserDataZonesDefault.value
  );
  const [selected, setSelected] = useState(
    fieldSelectedDataZones.value ? fieldSelectedDataZones.value : []
  );
  const isAllSelected =
    dataZones.length > 0 && selected.length === dataZones.length;

  const userDataZonesRequiredError =
    !userDataZonesDefault && (!selected || selected.length < 1);

  function selectDataZones(event) {
    let newSelectedValueList = event.target.value;

    if (newSelectedValueList.includes('all')) {
      newSelectedValueList =
        selected.length === dataZones.length
          ? []
          : dataZones.map((zone) => zone.id);
    }

    setSelected(newSelectedValueList);
    setFieldValue(USER_DATAZONES_KEY, newSelectedValueList);
  }

  function toggleDataZonesDefault(event) {
    const checked = event.target.checked;
    setUserDataZonesDefault(checked);
    setFieldValue(USER_DATAZONES_DEFAULT_KEY, checked);

    if (checked) {
      const newSelectedValueList = [];
      setSelected(newSelectedValueList);
      setFieldValue(USER_DATAZONES_KEY, newSelectedValueList);
    }
  }

  function getFormHelperText() {
    if (userDataZonesDefault) {
      return <span>&#8203;</span>;
    }

    if (userDataZonesRequiredError) {
      return (
        <span>
          <Icon
            className={`fa fa-exclamation-triangle ${classes.warningIcon}`}
          />
          <span> {t('managUsers.register.dataZoneRequired')}</span>
        </span>
      );
    }
    return (
      <span>
        <Icon className={`fa fa-info-circle ${classes.infoIcon}`} />
        <span> {t('managUsers.register.includingDefaultDataZone')}</span>
      </span>
    );
  }

  return (
    <div className={classes.dataZonesWrap}>
      <InputLabel
        id="UserDataZonesSelect-label"
        disableAnimation
        shrink={false}
        className={classesTitle.dataZoneTitle}
      >
        {t('dashboard.articleDataZones.name')}
      </InputLabel>
      <FormControlLabel
        className={classes.defaultZoneCheckbox}
        control={
          <Checkbox
            checked={userDataZonesDefault}
            onChange={toggleDataZonesDefault}
            name={USER_DATAZONES_DEFAULT_KEY}
          />
        }
        label={t('managUsers.register.defaultDataZone')}
      />
      <FormControl
        variant="filled"
        fullWidth={true}
        disabled={Boolean(userDataZonesDefault)}
        error={userDataZonesRequiredError}
      >
        <Select
          name={USER_DATAZONES_KEY}
          labelId="UserDataZonesSelect-label"
          multiple
          value={selected}
          onChange={(event) => {
            selectDataZones(event, false);
          }}
          displayEmpty={true}
          error={userDataZonesRequiredError}
          input={
            <OutlinedInput
              notched={false}
              label={t('dashboard.articleDataZones.name')}
            />
          }
          renderValue={(selectedToRender) => {
            if (userDataZonesDefault) {
              return '-';
            }

            const selectedLength =
              selectedToRender != null ? selectedToRender.length : 0;

            if (selectedLength == 1) {
              return t('managUsers.register.dataZoneSelection', {
                count: selectedLength
              });
            }
            return t('managUsers.register.dataZonesSelection', {
              count: selectedLength
            });
          }}
        >
          <MenuItem value="all" className={classes.checkAllListItem}>
            <ListItemIcon>
              <Checkbox
                classes={{ indeterminate: classes.indeterminateColor }}
                checked={isAllSelected}
                indeterminate={
                  selected.length > 0 && selected.length < dataZones.length
                }
              />
            </ListItemIcon>
            <ListItemText primary={t('managUsers.register.allDataZones')} />
          </MenuItem>
          {dataZones.map((zone) => (
            <MenuItem key={zone.id} value={zone.id}>
              <ListItemIcon>
                <Checkbox checked={selected.indexOf(zone.id) > -1} />
              </ListItemIcon>
              <ListItemText primary={zone.label} />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText component="div">{getFormHelperText()}</FormHelperText>
      </FormControl>
    </div>
  );
}

export default UserDataZonesSelect;
