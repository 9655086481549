import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import i18n from 'src/i18n';

import {
  DialogContent,
  DialogTitle,
  TextField,
  makeStyles
} from '@material-ui/core';

import ajaxGet from 'src/services/ajaxGet.js';
import { ListPanelContent } from './List';
import ajaxCall from 'src/services/ajaxCall';

export const PANELTYPE = 'ArticleDataZones';
export const ARTICLE_DATA_ZONES_CONFIG_I18KEY =
  'dashboard.articleDataZones.config';

export function getArticleDataZonesTitle() {
  return i18n.t('dashboard.articleDataZones.name');
}

const URL = 'adjustPermissionsDashboard/articleDataZone';

const useStylesCreateDialog = makeStyles(() => ({
  outlinedComponent: { marginBottom: '2rem' }
}));

export function ArticleDataZonesPanelConfig() {
  return <></>;
}

export function ArticleDataZonesPanelContent({ panelItem, contentSize }) {
  const { t } = useTranslation();
  const classes = useStylesCreateDialog();

  const [createTitle, setCreateTitle] = useState('');

  const columns = [
    {
      field: 'dataZoneName',
      headerName: t('common.name'),
      sortingOrder: 'label',
      minwidth: 100,
      editable: false,
      getCellDisplay: TitleDisplay
    },
    {
      field: 'timeStamp',
      headerName: 'Timestamp',
      type: 'dateTime',
      sortingOrder: 'timeStamp',
      minWidth: 150,
      editable: false,
      getCellDisplay: TimeStampDisplay
    }
  ];

  const DEFAULT_ROW = {
    id: 'all',
    instanceClassName: null,
    label: t('dashboard.articleDataZones.dataZoneDefault'),
    nolistEdit: true,
    firstElementAlways: true,
    timeStamp: null
  };
  const [rows, setRows] = useState([DEFAULT_ROW]);

  let timeStampLocale = 'en-GB';
  const userLang = document.documentElement.lang || 'en';
  switch (userLang) {
    case 'de':
      timeStampLocale = 'de-DE';
      break;
    case 'fr':
      timeStampLocale = 'fr-FR';
      break;
    case 'it':
      timeStampLocale = 'it-IT';
      break;
  }

  useEffect(() => {
    ajaxGet('articleDataZones', {filterUserDataZones: false}).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        const listRows = ajaxData.response.dataZones.map((item) => {
          const obj = Object.assign({}, item);
          obj.timeStamp = new Date(obj.timeStamp);
          return obj;
        });

        listRows.sort((a, b) => a.label.localeCompare(b.label));

        setRows([DEFAULT_ROW].concat(listRows));
      }
    });
  }, []);

  function TimeStampDisplay(row) {
    if (row.timeStamp) {
      return row.timeStamp.toLocaleString(timeStampLocale, {
        dateStyle: 'medium',
        timeStyle: 'medium'
      });
    } else {
      return '';
    }
  }

  function TitleDisplay(row) {
    return row.label;
  }

  function changeCreateTitle(event) {
    setCreateTitle(event.target.value);
  }

  function saveCreateDataZone() {
    ajaxCall({
      httpMethod: 'put',
      url: URL,
      params: {
        title: createTitle
      }
    }).then((ajaxData) => {
      let response = ajaxData.response;
      if (!ajaxData.error && response) {
        TOGO.Util.notifyResponse(response.message, !response.success);
        if (response.success && response.dataZones.length) {
          const resultZone = response.dataZones[0];
          const newZone = Object.assign({}, resultZone);
          newZone.timeStamp = new Date(resultZone.timeStamp);
          setRows(rows.concat(newZone));

          setCreateTitle('');
        }
      }
    });
  }

  function CreateDataZoneDialog() {
    return (
      <>
        <DialogTitle id="create-datazone-dialog-title">
          {t('dashboard.articleDataZones.create')}
        </DialogTitle>
        <DialogContent>
          <TextField
            variant="outlined"
            fullWidth
            label={t('common.name')}
            autoFocus
            className={classes.outlinedComponent}
            value={createTitle}
            onChange={changeCreateTitle}
          />
        </DialogContent>
      </>
    );
  }

  function deleteDataZone(row) {
    if (row.id == 'all') return;

    ajaxCall({
      httpMethod: 'delete',
      url: URL,
      params: {
        instanceId: row.id
      }
    }).then((ajaxData) => {
      let response = ajaxData.response;
      if (!ajaxData.error && response) {
        TOGO.Util.notifyResponse(response.message, !response.success);

        if (response.success) {
          setRows((prevRows) =>
            prevRows.filter((dataZone) => dataZone.id !== row.id)
          );
        }
      }
    });
  }

  function EditDataZoneDialog({ row, saving, setSaving }) {
    const [editTitle, setEditTitle] = useState(row.label);

    useEffect(() => {
      if (saving) {
        saveEditDataZone();
      }
    }, [saving]);

    function changeEditTitle(event) {
      setEditTitle(event.target.value);
    }

    function saveEditDataZone() {
      if (row.id == 'all') return;

      ajaxCall({
        httpMethod: 'post',
        url: URL,
        params: {
          instanceId: row.id,
          title: editTitle
        }
      }).then((ajaxData) => {
        let response = ajaxData.response;
        if (!ajaxData.error && response) {
          TOGO.Util.notifyResponse(response.message, !response.success);
          if (response.success && response.dataZones.length) {
            const resultZone = response.dataZones[0];
            const newZone = Object.assign({}, resultZone);
            newZone.timeStamp = new Date(resultZone.timeStamp);

            setRows((prevRows) =>
              prevRows.map((prevDataZone) =>
                prevDataZone.id === row.id ? newZone : prevDataZone
              )
            );

            setEditTitle('');
          }
        }

        setSaving(false);
      });
    }

    return (
      <>
        <DialogTitle id="edit-datazone-dialog-title">
          {t('dashboard.articleDataZones.edit')}
        </DialogTitle>
        <DialogContent>
          <TextField
            variant="outlined"
            fullWidth
            label={t('common.name')}
            autoFocus
            className={classes.outlinedComponent}
            value={editTitle}
            onChange={changeEditTitle}
          />
        </DialogContent>
      </>
    );
  }

  return (
    <ListPanelContent
      panelItem={panelItem}
      columns={columns}
      rows={rows}
      contentSize={contentSize}
      listEdit={{
        select: true,
        create: true,
        delete: true,
        edit: true
      }}
      CreateRowDialog={CreateDataZoneDialog}
      saveCreateRow={saveCreateDataZone}
      EditRowDialog={EditDataZoneDialog}
      deleteRow={deleteDataZone}
    ></ListPanelContent>
  );
}
