import React from 'react';
import {
  makeStyles,
  Typography
} from '@material-ui/core';

const useImageStyles = makeStyles(() => ({
  imageContent: {
    maxHeight: '1.8rem',
  }
}));

const TextWithImages = ({ text }) => {
  const imageClasses = useImageStyles();
  const imgTagRegex = /<img[^>]*>/g;

  const parts = [];
  let lastIndex = 0;

  if (!text) {
    return null;
  }

  text.replace(imgTagRegex, (match, offset) => {
    if (lastIndex < offset) {
      parts.push(text.slice(lastIndex, offset));
    }
    parts.push(match);
    lastIndex = offset + match.length;
    return match;
  });

  if (lastIndex < text.length) {
    parts.push(text.slice(lastIndex));
  }

  const elements = parts.map((part, index) => {
    if (part.startsWith('<img')) {
      const src = part.match(/src="([^"]*)"/);
      if (src) {
        return <img key={index} src={src[1]} alt="" className={imageClasses.imageContent} />;
      }
    } else {
      return <span key={index}>{part}</span>;
    }
    return null;
  });

  return [elements];
};

export default function ContentLangText({ contentText, classes, subTitle=false }) {
  if (!contentText) return null;

  const gutterBottomSetup = subTitle ? false : true;
  const classNameSetup = subTitle ? classes.subTitle : classes.title;

  return (
    <>
      {!contentText.missingContentLang ? (
        <Typography gutterBottom={gutterBottomSetup} className={classNameSetup} noWrap={true} component="div">
          <TextWithImages text={contentText.text} />         
        </Typography>
      ) : (

        <Typography gutterBottom={gutterBottomSetup} className={classNameSetup} noWrap={true} component="div">
                 &lt;+{contentText.typeName}:<TextWithImages text={contentText.text} />
                &gt;
        </Typography>
      )}
      {contentText.textAddon && (
        <span className="contentLangTextAddon">{contentText.textAddon}</span>
      )}
    </>
  );
}
