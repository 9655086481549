import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField, LinearProgress } from '@material-ui/core';

import { useTitleStyles } from './FeatureComponent';
import { TableDataContext } from '../../tabeditor/tools/Table';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(() => ({
  dateEditor: {
    width: '15rem',
    '& > .MuiInputBase-root': {
      height: '44px'
    }
  },
  dateEditorInTable: {
    maxWidth: '25rem',
    width: '14rem',
    '& > .MuiInputBase-root': {
      height: '44px'
    }
  }
}));

export const getDatePayloadData = (value) => ({
  dateValue: value
});

export const Date = ({
  featureInfo,
  date,
  setValueState,
  saveChangedValue,
  loading,
  error
}) => {
  const { t } = useTranslation();
  const [isInTableContext] = useContext(TableDataContext);
  const classesTitle = useTitleStyles();
  const classes = useStyles();

  const title = isInTableContext ? null : featureInfo.title;
  const mandatory = featureInfo.mandatory;

  const hasDefaultData = featureInfo.hasDefaultData;

  let dateString;
  if (date && date.year && date.month && date.day) {
    dateString = date.year + '-' + date.month + '-' + date.day;
  } else {
    dateString = '';
  }

  const [blurState, setBlurState] = useState(dateString);

  const isMandatoryError = mandatory && dateString == '';
  error = error || '';
  let helper = '';
  if (loading) {
    helper = <LinearProgress color="secondary" />;
  } else if (isMandatoryError && !hasDefaultData) {
    helper = t('common.errorMandatory');
  }

  function saveValue(newDateString) {
    if (blurState == newDateString || loading) return;
    setBlurState(newDateString);

    let newValue = null;

    if (newDateString) {
      newValue = getDateValueObject(newDateString);
    } else if (mandatory && !hasDefaultData) {
      return;
    }

    setValueState(newValue);
    saveChangedValue(newValue, getDatePayloadData(newValue));
  }

  function getDateValueObject(newDateString) {
    const dateSplitted = newDateString.split('-');
    return {
      year: dateSplitted[0],
      month: dateSplitted[1],
      day: dateSplitted[2]
    };
  }

  function showError() {
    if (!error) return;
    TOGO.Util.notifyResponse(error, true);
  }

  return (
    <TextField
      label={title}
      InputLabelProps={{
        shrink: false,
        disableAnimation: true,
        className: classesTitle.title
      }}
      className={
        isInTableContext ? classes.dateEditorInTable : classes.dateEditor
      }
      variant={isInTableContext ? 'standard' : 'outlined'}
      value={dateString}
      type="date"
      error={Boolean(error) || (isMandatoryError && !hasDefaultData)}
      FormHelperTextProps={{ component: 'div' }}
      helperText={helper}
      onMouseEnter={showError}
      onChange={(event) => saveValue(event.target.value)}
    />
  );
};
