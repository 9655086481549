import React, { useState, useEffect, useRef  } from 'react';
import { useTranslation } from 'react-i18next';

import * as Yup from 'yup';
import { Formik, Form, useFormikContext } from 'formik';
import { useTitleStyles } from '../articledata/datamanagement/featurecomponents/FeatureComponent';

import { Grid, Box, Button, Select, MenuItem , InputLabel} from '@material-ui/core';

import Uplaod  from '@material-ui/core/TextField';

import  { usePimStyles } from '../PimTogoTheme';

import Textfield from '../user/FormsUI/TextField';

// import Uplaod from '../sftpUser/FormsUI/Uplaod';

import ajaxPost from 'src/services/ajaxPost';

import FocusError from '../user/FocusError';



export const SFTPConnectionConfigurationForm = ({
	handleCloseDialogSFTPConnectionConfiguration,
	initFormSFTPConnectionConfigurationState,
	toCreateSFTPConnectionConfiguration,
	searchsftpConnectionConfigurations
}) => {

	const [testConnection, setTestConnection] = useState(false);
	const [connectionType, setConnectionType] = useState(true);

	const { t } = useTranslation();

	const classesTitle = useTitleStyles();

	const classesPim = usePimStyles();

	const formikRef = useRef();

	const FORM_VALIDATION_SFTP_CONNECTION_CONFIG_ = Yup.object().shape({
		id:'',
		serverName: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
		serverURL: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
		downloadFolder: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
		user: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
		privateKey: Yup.mixed().required(t('manageOrganization.organization.requiredFieldErrorMessage'))
	});
		const FORM_VALIDATION_SFTP_CONNECTION_CONFIG = Yup.object().shape({
			id:'',
			serverName: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
			serverURL: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
			downloadFolder: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
			user: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
			password:  Yup.string().required('Bitte geben Sie Ihr Passwort ein')
		});

	useEffect(() => {}, []);

	function handleChangeConnectionTye(event) {
		const val = event.target.value;
		setConnectionType(val);
  }
	return (
		<Formik
			initialValues={{
				...initFormSFTPConnectionConfigurationState
			}}
			innerRef={formikRef}
			enableReinitialize={true}
			validationSchema={connectionType ? FORM_VALIDATION_SFTP_CONNECTION_CONFIG:FORM_VALIDATION_SFTP_CONNECTION_CONFIG_}
			onSubmit={(values) => {
				console.log(values);
				var fileFormData = new FormData();
				fileFormData.append('file', values?.privateKey);

				let params = {};
				params.id = values?.id;
				params.user = values?.user;
				params.downloadFolder = values?.downloadFolder;
				params.serverURL = values?.serverURL;
				params.serverName = values?.serverName;
				params.password = values?.password;

				if (testConnection) {
					setTestConnection(false);
					ajaxPost(
						'sftpConnectionConfigManagement/testCpnnection',
						{},
						null,
						fileFormData
					).then((ajaxData) => {
						if (!ajaxData.error && ajaxData.response) {
							handleCloseDialogSFTPConnectionConfiguration();
							TOGO.Util.notifyResponse(ajaxData.response.message, false);
							searchsftpConnectionConfigurations(true);
						}
					});
				} else {
					if (toCreateSFTPConnectionConfiguration == true) {
						ajaxPost(
							'sftpConnectionConfigManagement/create',
							params,
							null,
							fileFormData
						).then((ajaxData) => {
							if (!ajaxData.error && ajaxData.response) {
								handleCloseDialogSFTPConnectionConfiguration();
								TOGO.Util.notifyResponse(ajaxData.response.message, false);
								searchsftpConnectionConfigurations(true);
							}
						});
					} else {
						ajaxPost(
							'sftpConnectionConfigManagement/editDone',
							params,
							null,
							fileFormData
						).then((ajaxData) => {
							if (!ajaxData.error && ajaxData.response) {
								handleCloseDialogSFTPConnectionConfiguration();
								TOGO.Util.notifyResponse(ajaxData.response.message, false);
								searchsftpConnectionConfigurations(false);
							}
						});
					}
				}
			}}
		>
			<Form>
				<FocusError />
				<Box className={classesPim.gridItem}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Textfield
								name="serverName"
								label={t('managUsersSFTP.name') + '*'}
								InputLabelProps={{
									shrink: false,
									disableAnimation: true,
									className: classesTitle.title
								}}
								className={classesTitle.togoInputStyle}
								variant={'outlined'}
							></Textfield>
						</Grid>
						<Grid item xs={12}>
							<Textfield
								name="serverURL"
								label={t('managUsersSFTP.serverUrl') + '*'}
								InputLabelProps={{
									shrink: false,
									disableAnimation: true,
									className: classesTitle.title
								}}
								className={classesTitle.togoInputStyle}
								variant={'outlined'}
							></Textfield>
						</Grid>
						<Grid item xs={12}>
							<Textfield
								name="downloadFolder"
								label={t('managUsersSFTP.downLoadFolder') + '*'}
								InputLabelProps={{
									shrink: false,
									disableAnimation: true,
									className: classesTitle.title
								}}
								className={classesTitle.togoInputStyle}
								variant={'outlined'}
							></Textfield>
						</Grid>
						<Grid item xs={12}>
							<Textfield
								name="user"
								label={t('managUsersSFTP.user') + '*'}
								InputLabelProps={{
									shrink: false,
									disableAnimation: true,
									className: classesTitle.title
								}}
								className={classesTitle.togoInputStyle}
								variant={'outlined'}
							></Textfield>
						</Grid>
						<Grid item xs={12}>
								<InputLabel  id="VerbindungsartLabel">{t('managUsersSFTP.connectionType')}</InputLabel>
								<Select
									labelId="VerbindungsartLabel"
									id="VerbindungsartSelect"
									value={connectionType}
									onChange={handleChangeConnectionTye}
									label="Verbindungsart"
									// InputLabelProps={{
									// 	shrink: false,
									// 	disableAnimation: true,
									// 	className: classesTitle.title 
									// }}
									className={classesTitle.togoInputStyle}>
									<MenuItem selected value={true}>{t('managUsersSFTP.password')} </MenuItem>
									<MenuItem  value={false}>{t('managUsersSFTP.privateKey')}</MenuItem>
								</Select>
						</Grid>
						{connectionType ? (<Grid item xs={12}>
							<Textfield
								type="password"
								name="password"
								label={t('managUsersSFTP.password') + '*'}
								InputLabelProps={{
									shrink: false,
									disableAnimation: true,
									className: classesTitle.title
								}}
								className={classesTitle.togoInputStyle}
								variant={'outlined'}
							></Textfield>
						</Grid>
						):(<Grid item xs={12}>
							<Uplaod
								id="id_PK"
								type="file"
								name="privateKey"
								label={t('managUsersSFTP.privateKey') + '*'}
								InputLabelProps={{
									shrink: false,
									disableAnimation: true,
									className: classesTitle.title
								}}
								className={classesTitle.togoInputStyle}
								variant={'outlined'}
								onChange={(event) => {
									if (formikRef.current) {
										 formikRef.current.setFieldValue(
											 "privateKey",
											 event.currentTarget?.files[0]
										 );
									 }
								 }}
							></Uplaod>
						</Grid>)}
					</Grid>
				</Box>
				<div
					style={{
						display: 'inline-flex',
						width: '100%',
						position: 'sticky',
						bottom: '0',
						zIndex: '9'
					}}
				>
					<Button
						type="submit"
						className={`${classesPim.addAction} ${classesPim.dialogBottom}`}
						style={{ width: '50%' }}
					>
						{toCreateSFTPConnectionConfiguration
							? t('common.create')
							: t('managUsers.register.saveUser')}
					</Button>

					<Button
						onClick={handleCloseDialogSFTPConnectionConfiguration}
						className={`${classesPim.deleteAction} ${classesPim.dialogBottom}`}
						style={{ width: '50%', marginLeft: '1rem' }}
					>
						{t('managUsers.register.cancel')}
					</Button>
				</div>
			</Form>
		</Formik>
	);
};

export default SFTPConnectionConfigurationForm;
