import React, {useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import * as Yup from 'yup';
import { Formik,Form } from 'formik';
import { useTitleStyles } from '../articledata/datamanagement/featurecomponents/FeatureComponent';
import {
  Icon,
  Grid,
  Box,
  Button
} from '@material-ui/core';


import { usePimStyles } from '../PimTogoTheme';

import Textfield from '../user/FormsUI/TextField';

import Select from '../user/FormsUI/Select';
//import Button from '../user/FormsUI/Button';

import ajaxPost from 'src/services/ajaxPost';

import FocusError from '../user/FocusError';
import { countries } from './ressources/contries/countries';

export const OrganizationForm = ({
  open,
  handleClose,
  initFormState,
  isLocked,
  internalEdit,
  toCreateOrga,
  currentPage,
  setOrganizations,
  organizations
}) => {
  const { t } = useTranslation();
  const classesPim = usePimStyles();
  const classesTitle = useTitleStyles();

  const phoneRegExp =
    /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}\s*[(]{0,1}[0-9]{1,7}[)]{0,1}[-\s./0-9]*$/;

  const FORM_VALIDATION = Yup.object().shape({
    id: '',
    company: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    companyAffix: '',
    taxnumber: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    pdfNameBuilder: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    department: '',
    street: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    streetNumber: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    postalCode: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    city: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    country: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
    phone: Yup.string()
      .required(t('manageOrganization.organization.requiredFieldErrorMessage'))
      .matches(phoneRegExp, t('manageOrganization.organization.inputPhoneNumberRegExpErrorMessage')),
    mobile: Yup.string().matches(
      phoneRegExp,
      t('manageOrganization.organization.inputPhoneNumberRegExpErrorMessage')
    ),
    fax: Yup.string().matches(
      phoneRegExp,
      t('manageOrganization.organization.inputPhoneNumberRegExpErrorMessage')
    ),
    email: Yup.string().email(t('manageOrganization.organization.inputMailRegExpErrorMessage')),
    website: Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      t('manageOrganization.organization.inputWebSiteRegExpErrorMessage')
    ),
    organisationState: ''
  });

  const pdfNameBuilderList = { Standard: 'Standard', Krempel: 'Krempel' };
  const organisationStates__ = {
    ACTIVE: t('manageOrganization.organization.active'),
    SILENT: t('manageOrganization.organization.silent'),
    LOCKED: t('manageOrganization.organization.locked')
  };
  const organisationStates = { ACTIVE: t('manageOrganization.organization.active'), SILENT: t('manageOrganization.organization.silent') };

  useEffect(() => {}, []);

  return (
    <>
      <Formik
        initialValues={{
          ...initFormState
        }}
        enableReinitialize={true}
        validationSchema={FORM_VALIDATION}
        onSubmit={(values) => {
          if (toCreateOrga) {
            ajaxPost('organizationManagement/create', values).then(
              (ajaxData) => {
                if (!ajaxData.error && ajaxData.response) {
                  handleClose();
                  organizations = { organizations };
                  TOGO.Util.notifyResponse(ajaxData.response.message, false);
                  //   organizations.puch(ajaxData.response.orgaInView)
                  //   setOrganizations(organizations);
                }
              }
            );
          } else {
            ajaxPost('organizationManagement/edit', values).then((ajaxData) => {
              if (!ajaxData.error && ajaxData.response) {
                handleClose();
                organizations.forEach((element) => {
                  if (element.id == values.id) {
                    element.organizationForm =
                      ajaxData.response.orgaInView.organizationForm;
                  }
                });
                setOrganizations(organizations);
                TOGO.Util.notifyResponse(ajaxData.response.message, false);
              }
            });
          }
        }}
      > 
        <Form>
          <FocusError />
          <Box className={classesPim.gridItem}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Textfield
                  name="company"
                  label={t('manageOrganization.organization.name') + "*"}
                  InputLabelProps={{
                    shrink: false,
                    disableAnimation: true,
                    className: classesTitle.title
                  }}
                  className={classesTitle.togoInputStyle}
                  variant={'outlined'}
                ></Textfield>
              </Grid>

              <Grid item xs={12}>
                <Textfield
                  name="companyAffix"
                  label={t('manageOrganization.organization.nameAffix')}
                  InputLabelProps={{
                    shrink: false,
                    disableAnimation: true,
                    className: classesTitle.title
                  }}
                  className={classesTitle.togoInputStyle}
                  variant={'outlined'}
                ></Textfield>
              </Grid>
              <Grid item xs={12}>
                <Textfield
                  name="taxnumber"
                  label={t('manageOrganization.organization.taxnumber')}
                  InputLabelProps={{
                    shrink: false,
                    disableAnimation: true,
                    className: classesTitle.title
                  }}
                  className={classesTitle.togoInputStyle}
                  variant={'outlined'}
                ></Textfield>
              </Grid>
              <Grid item xs={12}>
                <Select
                  name="pdfNameBuilder"
                  label={t('manageOrganization.organization.pdfNameBuilder')}
                  options={pdfNameBuilderList}
                  InputLabelProps={{
                    shrink: false,
                    disableAnimation: true,
                    className: classesTitle.title
                  }}
                  className={classesTitle.togoInputStyle}
                />
              </Grid>
              <Grid item xs={12}>
                <Textfield
                  name="department"
                  label={t('manageOrganization.organization.department')}
                  InputLabelProps={{
                    shrink: false,
                    disableAnimation: true,
                    className: classesTitle.title
                  }}
                  className={classesTitle.togoInputStyle}
                  variant={'outlined'}
                ></Textfield>
              </Grid>
              <Grid item xs={12}>
                <Textfield
                  name="street"
                  label={t('manageOrganization.organization.street')}
                  InputLabelProps={{
                    shrink: false,
                    disableAnimation: true,
                    className: classesTitle.title
                  }}
                  className={classesTitle.togoInputStyle}
                  variant={'outlined'}
                ></Textfield>
              </Grid>
              <Grid item xs={12}>
                <Textfield
                  name="streetNumber"
                  label={t('manageOrganization.organization.streetNumber')}
                  InputLabelProps={{
                    shrink: false,
                    disableAnimation: true,
                    className: classesTitle.title
                  }}
                  className={classesTitle.togoInputStyle}
                  variant={'outlined'}
                ></Textfield>
              </Grid>
              <Grid item xs={12}>
                <Textfield
                  name="postalCode"
                  label={t('manageOrganization.organization.postalCode')}
                  InputLabelProps={{
                    shrink: false,
                    disableAnimation: true,
                    className: classesTitle.title
                  }}
                  className={classesTitle.togoInputStyle}
                  variant={'outlined'}
                ></Textfield>
              </Grid>
              <Grid item xs={12}>
                <Textfield
                  name="city"
                  label={t('manageOrganization.organization.city') + "*"}
                  InputLabelProps={{
                    shrink: false,
                    disableAnimation: true,
                    className: classesTitle.title
                  }}
                  className={classesTitle.togoInputStyle}
                  variant={'outlined'}
                ></Textfield>
              </Grid>
              <Grid item xs={12}>
                <Select
                  name="country"
                  label={t('manageOrganization.organization.country') + "*"}
                  options={t('manageOrganization.organization.countries') == "de_countries" ? countries.de: countries.en}
                  InputLabelProps={{
                    shrink: false,
                    disableAnimation: true,
                    className: classesTitle.title
                  }}
                  className={classesTitle.togoInputStyle}
                />
              </Grid>
              <Grid item xs={12}>
                <Textfield
                  name="phone"
                  label={t('manageOrganization.organization.phone')}
                  InputLabelProps={{
                    shrink: false,
                    disableAnimation: true,
                    className: classesTitle.title
                  }}
                  className={classesTitle.togoInputStyle}
                  variant={'outlined'}
                ></Textfield>
              </Grid>
              <Grid item xs={12}>
                <Textfield
                  name="fax"
                  label={t('manageOrganization.organization.fax')}
                  InputLabelProps={{
                    shrink: false,
                    disableAnimation: true,
                    className: classesTitle.title
                  }}
                  className={classesTitle.togoInputStyle}
                  variant={'outlined'}
                ></Textfield>
              </Grid>
              <Grid item xs={12}>
                <Textfield
                  name="email"
                  label={t('manageOrganization.organization.email')}
                  InputLabelProps={{
                    shrink: false,
                    disableAnimation: true,
                    className: classesTitle.title
                  }}
                  className={classesTitle.togoInputStyle}
                  variant={'outlined'}
                ></Textfield>
              </Grid>
              <Grid item xs={12}>
                <Textfield
                  name="website"
                  label={t('manageOrganization.organization.webSite')}
                  InputLabelProps={{
                    shrink: false,
                    disableAnimation: true,
                    className: classesTitle.title
                  }}
                  className={classesTitle.togoInputStyle}
                  variant={'outlined'}
                ></Textfield>
              </Grid>
              {!toCreateOrga && !internalEdit && (
                <Grid item xs={12}>
                  {isLocked ? (
                    <Select
                      name="organisationState"
                      label={t('manageOrganization.organization.organisationState')}
                      options={organisationStates__}
                      InputLabelProps={{
                        shrink: false,
                        disableAnimation: true,
                        className: classesTitle.title
                      }}
                      className={classesTitle.togoInputStyle}
                    />
                  ) : (
                    <Select
                      name="organisationState"
                      label={t('manageOrganization.organization.organisationState')}
                      options={organisationStates}
                      InputLabelProps={{
                        shrink: false,
                        disableAnimation: true,
                        className: classesTitle.title
                      }}
                      className={classesTitle.togoInputStyle}
                    />
                  )}
                </Grid>
              )}
            </Grid>
          </Box>
          <div
            style={{
              display: 'inline-flex',
              width: '100%',
              position: 'sticky',
              bottom: '0',
              zIndex: '9'
            }}
          >
            <Button
              // startIcon={<Icon className="fa fa-plus" />}
              type="submit"
              className={`${classesPim.addAction} ${classesPim.dialogBottom}`}
              style={{ width: '50%' }}
            >
            {toCreateOrga
            ? t('common.create')
            : t('managUsers.register.saveUser') }
            </Button>
            {!internalEdit && 
              <Button
                onClick={handleClose}
                className={`${classesPim.deleteAction} ${classesPim.dialogBottom}`}
                style={{ width: '50%', marginLeft: '1rem' }}
              >
                {t('managUsers.register.cancel')}
              </Button>
            }
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default OrganizationForm;
