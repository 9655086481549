import React from 'react';

import ContentLangText from '../ProductList/ContentLangText';
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Link from '@material-ui/core/Link';


export default function ProductContent({product, classes}) {
 
const linkUrl = TOGO.Util.getBasePath() + product.url;


  return (
    <Link href={linkUrl}>
      <div className=" col contentlangRelevantItem pictureWrapper_ responsiveImage_">
        {product.itemPicture && (
          <>
            {product.itemPicture.noThumbnail ? (
              <>
                <div className="noPreviewSymbol">
                  {this.renderSwitch(product.itemPicture.contentTypeSymbol)}
                </div>
              </>
            ) : (
              <CardMedia
                src={product.itemPicture?.thumbnailUri}
                sx={{
                  bgcolor: '#00000008',
                  width: '11rem',
                  borderRadius: '9px ',
                  border: '0.5px solid #0000003b',
                  height: '9rem',
                  fontSize: 15,
                  objectFit: 'scale-down !important'
                }}
                variant="square"
                component="img"             
                title={product.itemPicture?.altText ? product.itemPicture?.altText : null}
              ></CardMedia>
            )}
          </>
        )}
      </div>
      <div
        className={
          ' col action editInstanceAction_ ' +
          (product.actions.length > 2 ? ' smallerTitle_' : '')
        }
      >
        <CardContent className={classes.cardContentStyle}>

        <ContentLangText
                  contentText={product.contentLangTitle}
                  classes={classes}
                />
        <ContentLangText
                  contentText={product.contentLangSubtitleA}
                  classes={classes}
                  subTitle={true}
                />
        <ContentLangText
                  contentText={product.contentLangSubtitleB}
                  classes={classes}
                  subTitle={true}
                />                
        </CardContent>
      </div>
    </Link>
  );
}
