import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import { List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Stack from '@mui/material/Stack';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import FacetBox from './FacetBox';
import Chip from '@material-ui/core/Chip';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import ajaxGet from '../../services/ajaxGet.js';
import ajaxPost from '../../services/ajaxPost.js';
import { mainPimColor } from '../PimTogoTheme';
import FacetContainer from './FacetContainer';

const useStyles_ = makeStyles((theme) => ({
  root: {
    width: '100%',
    //   maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    fontSize: '2rem',
    maxHeight: 300
  },
  listSection: {
    backgroundColor: 'inherit'
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0
  },
  column: {
    flexBasis: '33.33%'
  },
  heading: {
    fontSize: '1.5rem !important',
    fontWeight: '500 !important',
    color: 'white !important'
  },
  resetButton: {
    fontSize: '1.5rem !important',
    fontWeight: '500 !important',
    color: 'white !important',
    '&&:hover': {
      backgroundColor: ' rgb(0 0 0 / 15%)'
    }
  },
  facet: {
    fontSize: '1.2rem !important',
    fontWeight: 'bold !important'
  },
  facet_: {
    fontSize: '0.9rem !important'
    // fontWeight: 'bold !important'
  },
  listItemButtonStyle: {
    borderRight: '2px solid orange !important',
    '&.Mui-selected': {
      backgroundColor: 'unset !important',
      borderTop: '2px solid orange',
      borderBottom: '2px solid orange',
      borderRight: 'none !important'
    }
  },

  resetIcon: {
    '&.MuiChip-icon': {
      color: 'white !important'
    },
    '&.MuiSvgIcon-root ': {
      color: 'white !important'
    }
  }
}));

const isFacetValueChecked = (checked, value) => {
  if (value == undefined) {
    return false;
  }
  if (checked.length != 0) {
    for (let i = 0; i < checked.length; i++) {
      if (
        checked[i]?.searchIds[0] === value?.searchIds[0] &&
        value?.valueAsString === checked[i]?.valueAsString
      ) {
        return true;
      }
    }
    return false;
  } else {
    return false;
  }
};

export default function FilterProduct({
  setCountFoundTotal,
  setMultiClassificationSubscribed,
  setArticleDataEdit,
  setPaginationstate,
  setClassificationGroups,
  setProductsViewList,
  facets,
  setFacets,
  productStructureFilter,
  contentLanguage
}) {
  const classes = useStyles_();

  const [facetElement, setFacetElement] = React.useState({});
  const [allSearchIds, setAllSearchIds] = React.useState([]);
  const [checked, setChecked] = React.useState([]);
  const [filter, setFilter] = React.useState([]);

  const containeredFacets = {};
  const containerOrder = [];

  facets?.combinedFacets?.forEach((combinedFacet) => {
    const container = containeredFacets[combinedFacet.facetContainerLabel];
    if (container) {
      container.push(combinedFacet);
    } else {
      containeredFacets[combinedFacet.facetContainerLabel] = [combinedFacet];
      containerOrder.push(combinedFacet.facetContainerLabel);
    }
  });

  const customFieldsIndex = containerOrder.indexOf('customFields');
  if (customFieldsIndex !== -1) {
    containerOrder.splice(customFieldsIndex, 1);
    containerOrder.unshift('CustomFields');
    containeredFacets['CustomFields'] = containeredFacets['customFields'];
    delete containeredFacets['customFields'];
  }

  const handleListItemClick = (fixedFacet) => {
    setFacetElement(fixedFacet);
  };

  const openFilterClick = () => {
    ajaxGet('dataManagement/facets_', {
      // isNewFilter: isNewFilter,
      // currentPage: currentPage,
      // searchWithFacets: searchWithFacets,
      // searchTerm: searchTerm,
      contentLanguage: contentLanguage
      // lastPage: lastPage,
      // productStructureNode: productStructureNode,
      // productStructureNodePath: productStructureNodePath,
      //  elementsPerPage: elementsPerPage
    }).then((ajaxData) => {
      const response = ajaxData.response;
      if (!ajaxData.error && response) {
        setFacets(response.facets);
      }
    });
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    openFilterClick();
    setExpanded(isExpanded ? panel : false);
  };

  const updateSearch = (filter) => {
    const payload = {
      searchTerm: '*',
      curPage: null,
      elementsPerPage: null,
      filters: filter,
      isNewFilter: true
    };

    if (productStructureFilter && productStructureFilter.productStructureNode) {
      payload.productStructureNode =
        productStructureFilter.productStructureNode;
      payload.productStructureNodePath =
        productStructureFilter.productStructureNodePath;
    }

    ajaxPost('dataManagement_', {}, payload).then((ajaxData) => {
      let response = ajaxData.response;
      if (response)
        TOGO.Util.notifyResponse(response.message, !response.success);

      if (!ajaxData.error && response) {
        let productList = response.overviewModel.items;
        setProductsViewList([]);
        setCountFoundTotal(response.countFoundTotal);
        setArticleDataEdit(response.articleDataEdit);
        setPaginationstate(response.pagination);
        setClassificationGroups(response.groupModels);
        setFacets(response.facets);
        setMultiClassificationSubscribed(
          response.multiClassificationSubscribed
        );
        setProductsViewList(productList);
      }
    });
  };

  const resetFilterHandleClick = () => {
    setChecked([]);
    setAllSearchIds([]);
    setFilter([]);
    updateSearch([]);
  };

  const changeAllSearchIds = (item, bool) => {
    if (bool) {
      allSearchIds.push(item);
      setAllSearchIds(allSearchIds);
    } else {
      setAllSearchIds(allSearchIds.filter((org) => org !== item));
    }
  };

  //useEffect(() => {}, [facetElement,checked, allSearchIds]);

  if (
    (!facets?.fixedFacets || facets?.fixedFacets.length == 0) &&
    (!facets?.combinedFacets || facets?.combinedFacets.length == 0)
  ) {
    return null;
  }

  return (
    <div>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        sx={{
          backgroundColor: mainPimColor
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.resetIcon} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>Filter</Typography>
          </div>
          {allSearchIds.length != 0 && (
            <div className={classes.column}>
              <Chip
                label="Filter zurücksetzen"
                className={classes.resetButton}
                variant="outlined"
                onClick={resetFilterHandleClick}
                clickable={true}
                icon={<AutorenewIcon className={classes.resetIcon} />}
              />
            </div>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 0.5, sm: 0.5, md: 0.5 }}
            sx={{
              width: '100%',
              height: '30rem',
              fontSize: '1.5rem',
              backgroundColor: '#fff'
            }}
          >
            <Box
              sx={{
                width: '37.5%',
                height: '30rem',
                backgroundColor: '#fff',
                fontSize: '1.5rem'
              }}
            >
              <List
                className={classes.root}
                component="nav"
                aria-label="secondary mailbox folder"
              >
                <ListItemButton
                  dense={true}
                  divider={true}
                  className={classes.listItemButtonStyle}
                  //   selected={selectedIndex === 0}
                  //   onClick={(event) => handleListItemClick(event, 2)}
                >
                  {/* <ListItemText primary="Trash" /> */}
                </ListItemButton>
                {facets?.fixedFacets &&
                  facets?.fixedFacets.map((fixedFacet) => (
                    <ListItemButton
                      key={fixedFacet.facetIdentifier}
                      className={classes.listItemButtonStyle}
                      selected={
                        fixedFacet.facetIdentifier ===
                        facetElement.facetIdentifier
                      }
                      onClick={() => {
                        handleListItemClick(fixedFacet);
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            fontSize={12}
                            className={
                              allSearchIds.indexOf(
                                fixedFacet.facetIdentifier
                              ) != -1
                                ? classes.facet
                                : ''
                            }
                          >
                            {fixedFacet.label}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  ))}
                {containerOrder.map((containerLabel) => (
                  <FacetContainer
                    key={containerLabel}
                    containerLabel={containerLabel}
                    containerFacets={containeredFacets[containerLabel]}
                    handleListItemClick={handleListItemClick}
                    allSearchIds={allSearchIds}
                    facetElement={facetElement}
                    checked={checked}
                  />
                ))}
              </List>
            </Box>
            <Box
              sx={{
                width: '100%',
                height: '30rem',
                backgroundColor: '#fff'
              }}
            >
              <FacetBox
                filter={filter}
                setFilter={setFilter}
                facetElement={facetElement}
                allSearchIds={allSearchIds}
                changeAllSearchIds={changeAllSearchIds}
                updateSearch={updateSearch}
                checked={checked}
                setChecked={setChecked}
                isFacetValueChecked={isFacetValueChecked}
              ></FacetBox>
            </Box>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
